<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <!--ศึกษานิเทศก์ประเมิน สมัครผู้อำนวยการวิทยาลัย -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="assessment_directiors.period_vecprovince_enable === '1'"
          >
            <v-row cols="12" md="7">
              <v-col>
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <h2 class="secondary--text">
                    <v-icon color="secondary">mdi-domain</v-icon>
                    ศูนย์ส่งเสริมและพัฒนาอาชีวศึกษา
                  </h2>

                  <h3>
                    ดำเนินการ <u> ประเมิน ภาค ข ส่วนที่ 1 ผู้สมัครคัดเลือก</u>
                    <br />
                    <u>ตำแหน่ง ผู้อำนวยการวิทยาลัย </u>
                    <br />
                    ครั้งที่
                    {{ assessment_directiors.period_vecprovince_times }} /
                    {{ assessment_directiors.period_vecprovince_year }}

                    ก่อนระบบปิดในวันที่
                    {{
                      assessment_directiors.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                  <v-btn color="info" to="/supervisions/director_app_b">
                    <v-icon class="pr-2">mdi-account-convert</v-icon>
                    ผู้สมัคร</v-btn
                  >
                </v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ ตรวจสอบเอกสาร ผู้สมัครสอบ : <br />
                    {{
                      assessment_directiors.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      assessment_directiors.period_vecprovince_stop +
                        ' 24:00:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="12">
        <Userinfo />
      </v-col>
      <!--  <v-col cols="12" md="12">
        <PersonnelIvenCount/>
         </v-col>    
         <v-col cols="12" md="12">
            <studentStd/>
         </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import PersonnelIvenCount from "../../components/supervisions/personnelIvenCount.vue";
import studentStd from "../../components/supervisions/studentStd.vue";
import Userinfo from "../../components/userinfo.vue";
import FlipCountdown from "vue2-flip-countdown";

export default {
  name: "DashboardDashboard",
  data() {
    return {
      ApiKey: "HRvec2021",
      assessment_directiors: [],
      stop: {
        type: Boolean
      },
      showDays: {
        type: Boolean,
        required: false,
        default: true
      },
      showHours: {
        type: Boolean,
        required: false,
        default: true
      },
      showMinutes: {
        type: Boolean,
        required: false,
        default: true
      },
      showSeconds: {
        type: Boolean,
        required: false,
        default: true
      },

      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      countdownSize: {
        type: String,
        required: false
      },
      labelSize: {
        type: String,
        required: false
      }
    };
  },
  async mounted() {
    await this.period_directorAssQuery();
  },
  methods: {
    async period_directorAssQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovinc_id: "40102"
      });
      this.assessment_directiors = result_period.data;
    }
  },
  components: { FlipCountdown, PersonnelIvenCount, studentStd, Userinfo }
};
</script>
