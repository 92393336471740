<template>
  <div>
    <base-material-card color="success" class="px-5 py-3">
      <template v-slot:heading>
        <div class="font-weight-bold">
          {{ userinfos.user_firstname + userinfos.user_lastname }} 
        </div>
      </template>
      <v-card-text>
        <v-alert type="info" outlined >
          <div>
            รหัส : {{ userinfos.user_ID }}  
          </div>
          <div>
            {{ userinfos.user_firstname + userinfos.user_lastname }}
          </div>
          <div>
            ทีอยู่ : {{ userinfos.user_address || "-" }}  จังหวัด : {{ userinfos.province_name }}
          </div>
          <div>
            E-mail : {{ userinfos.user_email || "-" }} เบอร์โทร : {{ userinfos.user_tel_p || "-" }}  Fax : {{ userinfos.user_fax || "-" }}
          </div>
        </v-alert>     
      </v-card-text>
    </base-material-card>
  </div>
</template>

<script>
export default {
  name: "HRvecUserinfo",

  data() {
    return {
      ApiKey: "HRvec2021",
      userinfos: []
    };
  },

  async mounted() {
    await this.userQrery()
  },

  methods: {
    async userQrery() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("user.php", {
        user_ID: userSession.user_ID,
        ApiKey: "HRvec2021"
      });
      this.userinfos = result.data;
    }
  }
};
</script>

<style lang="scss" scoped></style>
