<template>
    <v-container id="dashboard" fluid tag="section">
      <v-row>
        <v-col cols="12" md="12">
          <base-material-card color="success" class="px-5 py-3">
            <template v-slot:heading>
              <div class="font-weight-light">
                ข้อมูลนักเรียน นักศึกษา
              </div>
            </template>
            <v-card-text>
              <v-data-table :headers="headers" :items="showAlldata">
                <template v-slot:top>
                  <v-text-field
                    v-model="search_branch"
                    label="ค้นหา :"
                    class="mx-4"
                  ></v-text-field>
                </template>
                <template v-slot:item.rate_work_college_id_class="{ item }">
                    <div>
                        <span v-if="item.rate_work_college_id_class==='20'"> ปวช.</span>
                        <span v-else-if="item.rate_work_college_id_class==='30'"> ปวส.</span>
                    </div>
                </template>
              </v-data-table>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "DashboardDashboard",
  
    data() {
      return {
        ApiKey: "HRvec2021",
        search_branch: "",
        headers: [
          { text: "วิทยาลัย", align: "left", value: "college_name" },
          { text: "ประเภทวิชา", align: "left", value: "subject_typeName" },   
          { text: "สาขาวิชา", align: "left", value: "branch_name_th" },  
          { text: "ระดับ", align: "left", value: "rate_work_college_id_class" },   
          { text: "ปวช.1/ปวส.1", align: "left", value: "rate_work_field_study_lavel_1" },   
          { text: "ปวช.2/ปวส.2", align: "left", value: "rate_work_field_study_lavel_2" },   
          { text: "ปวช.3", align: "left", value: "rate_work_field_study_lavel_3" },   
           
        ],      
  
        user: [],
        showAlldata: [],
        maxyears:[],
        pagination: {},
        rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      };
    },
    async mounted() {
      await this.getuser();
      await this.getYearMax();
      await this.getAlldata();
    },
  
    methods: {
      async getuser() {
        let result;
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        result = await this.$http.post("admin.php", {
          user_name: userSession.user_name,
          ApiKey: "HRvec2021"
        });
        this.user = result.data;
        
      },
  

      async getYearMax() {
        let result = await this.$http.post("rate_work_college.iven.php", {
            maxyears: "Ok",
          ApiKey: "HRvec2021"
        });
        this.maxyears = result.data;   
      },


      async getAlldata() {
        let result = await this.$http.post("rate_work_college.iven.php", {
          collegeVei: this.user.user_code,
          rate_work_college_year: this.maxyears.maxyear,          
          ApiKey: "HRvec2021"
        });
        this.showAlldata = result.data;        
      }
    }
  };
  </script>
  